<template>
	<h3>Hammer: Official Game Rules Overview</h3>
	<h4>Overview</h4>
	<ul>
		<li>Objective: Accumulate the most dots by the end of the round.</li>
		<li>
			Game Format: Wolf format with opportunities for “hammering” to double the
			dot count.
		</li>
		<li>Players: Typically 5 or more players, with an odd number preferred.</li>
	</ul>
	<h4>Setup and Order</h4>
	<ol>
		<li>
			<strong>Order of Play:</strong>
			<ul>
				<li>Players flip a tee to determine the initial order.</li>
				<li>
					The order set on the first tee box remains consistent throughout the
					entire round.
				</li>
			</ul>
		</li>
	</ol>
	<h4>Gameplay</h4>
	<ol>
		<li>
			<strong>First Player Options:</strong>
			<ul>
				<li>
					The player teeing off first has three choices:
					<ol>
						<li>
							Lone Wolf (3 dots): The player calls “wolf” before teeing off,
							choosing to play alone.
						</li>
						<li>
							Wolf (2 dots): The player decides to go solo after seeing all
							other players’ tee shots.
						</li>
						<li>
							Partner Up (1 dot): The player chooses a partner immediately after
							their tee shot.
						</li>
					</ol>
				</li>
				<li>
					Note: A partner must be chosen right after their tee shot; selections
					cannot be made retrospectively.
				</li>
			</ul>
		</li>
		<li>
			<strong>The Hammer:</strong>
			<ul>
				<li>
					Definition: “Hammering” refers to doubling the current dot count after
					all players have taken their next shots.
				</li>
				<li>
					The lead player, having decided to go wolf or partnered up, can hammer
					after any player has completed their shot.
				</li>
				<li>
					Any player on the opposing team may also choose to hammer if they feel
					confident their team can outperform the wolf.
				</li>
			</ul>
		</li>
	</ol>
	<h4>Example Scenario</h4>
	<ol>
		<li>
			<strong>Initial Hammer:</strong>
			<ul>
				<li>Five players are on a par-four hole.</li>
				<li>
					The first player goes wolf after the initial tee shots, setting the
					dot count at 2.
				</li>
				<li>
					Another player on the four-person team chooses to hammer, doubling the
					dot count to 4.
				</li>
			</ul>
		</li>
		<li>
			<strong>Approach Shot Hammer:</strong>
			<ul>
				<li>
					After the approach shots, the wolf hits a strong shot and hammers the
					dot count again, bringing it from 4 to 8.
				</li>
			</ul>
		</li>
		<li>
			<strong>Final Putt Hammer:</strong>
			<ul>
				<li>Approaching the green, assume:</li>
				<li>
					The wolf player has a 6-foot putt for birdie.
					<ul>
						<li>
							An opposing player has a 10-foot putt for birdie, with three
							teammates in for par.
						</li>
					</ul>
				</li>
				<li>
					The opposing player makes their 10-foot putt, hammers, and raises the
					dot count to 16.
				</li>
				<li>
					The wolf misses their putt, resulting in each player on the
					four-person team earning 16 dots.
				</li>
			</ul>
		</li>
	</ol>
	<h4>Scoring, Wagering, and Payouts</h4>
	<ul>
		<li>
			<strong>Dot Value:</strong> Dots can have any agreed-upon dollar value but
			are typically set at $1 per dot.
		</li>
		<li>
			<strong>Payouts:</strong> At the end of the round, players settle up based
			on dot totals.
		</li>
		<li>
			Each player pays out the difference in dot totals to opposing players.
		</li>
		<li>
			<strong>Example:</strong> If Player 1 has 30 dots and Player 2 has 42
			dots, Player 1 would owe Player 2 $12 (42 - 30 = 12).
		</li>
	</ul>
	<p>
		The structured rules ensure fair, strategic play with a straightforward
		payout system for settling scores at the end of the round.
	</p>
</template>

<script>
export default {
	name: 'Rules',
};
</script>
