<template>
	<div class="home">
		<HelloWorld />
	</div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue';

export default {
	name: 'HomeView',
	components: {
		HelloWorld,
	},
};
</script>
